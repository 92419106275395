<template>
    <div class="company-transfer">
        <div class="card">
            <a-form-model ref="ruleForm" :model="params" :rules="rules" v-bind="layout">
                <a-form-model-item has-feedback label="对方手机号" prop="phone" :labelCol="{span: 6}"
                                   :wrapperCol="{span: 12}">
                    
                    <div class="flex-row flex-j-start flex-a-center">
                        <a-input v-model="params.phone" type="text" autocomplete="off"/>
                        <van-button style="width: 4.0rem;margin-left: 0.2rem"
                                    :class="`${isSending ? 'sended' : 'send'}`" type="info"
                                    @click="onSendClick">{{
                                sendText }}</van-button>
                    </div>
                </a-form-model-item>
                
                
                <a-form-model-item has-feedback label="短信验证码" prop="code">
                    <a-input v-model="params.code" type="text" autocomplete="off"/>
                </a-form-model-item>
                <a-form-model-item label=" " :colon="false">
                    <van-button type="info" @click="onSubmit" block>提交</van-button>
                </a-form-model-item>
                <a-form-model-item label=" " :colon="false" :labelCol="{span: 6}" :wrapperCol="{span: 12}">
                    <span
                        style="color: #f1676d;line-height: 0.4rem !important;">温馨提示：进行企业帐号转移时，接收方（对方）需先注册“就在平度”微信小程序个人用户，并在“我的”——“认证资料”中补全手机号信息，提交成功后方可进行帐号接收。
                    </span>
                </a-form-model-item>
            </a-form-model>
        
        
        </div>
    </div>
</template>

<script>
// company-transfer
export default {
    name: "company-transfer",
    data() {
        return {
            layout: {
                labelCol: {span: 6},
                wrapperCol: {span: 12},
            },
            params: {
                code: "",
                phone: "",
            },
            rules: {
                code: [{required: true, message: '请输入对方短信验证码', trigger: 'blur'},],
                phone: [{required: true, message: '请输入对方手机号', trigger: 'blur'},],
            },
            sendText: "发送验证码",
            timeCount: 60,
            isSending: false,
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        }
    },
    mounted() {
    },
    methods: {
        async onSendClick() {
            if (this.isSending) {
                return
            }
            const params = {
                phone: this.params.phone
            };
            if ($app.isNullOrEmpty(params.phone)) {
                $app.showToast({
                    title: '请输入对方手机号',
                    icon: 'none'
                })
                return false;
            }
            
            const res = await $app.httpGet({
                path: `/Member/SendCaptchaCode`,
                params: params,
                loading: '正在发送...'
            });
            // const res = {ok: true, message: '发送成功'}
            if (res.ok) {
                this.isSending = true;
                $app.showToast({
                    title: res.message,
                })
                let timer = setInterval(() => {
                    let count = this.timeCount;
                    console.log(count)
                    if (count <= -1) {
                        clearInterval(timer);
                        timer = null;
                        this.isSending = false;
                        this.timeCount = 60;
                        this.sendText = "发送验证码";
                        return 0;
                    }
                    let text = `(${count})s`;
                    this.timeCount = --count;
                    this.sendText = text;
                    
                }, 1000);
            } else {
                    this.isSending = false;
                    this.timeCount = 60;
                    this.sendText = "发送验证码";
            }
            
        },
        //    自定义方法区域开始
        async onSubmit() {
            await this.validate();
            const params = this.params;
            params.id = this.userInfo.Id;
            const res = await $app.httpGet({
                path: `/Member/AccountTransfer`,
                params: params,
                loading: '正在提交...'
            });
            if (res.ok) {
                $app.showToast({
                    title: '转移成功',
                });
                this.loadUserInfo();
                
            }
        },
        async loadUserInfo() {
            const params = {
                id: this.userInfo.Id,
            };
            const res = await $app.httpGet({
                path: `/Member/GetUserInfo`,
                params: params,
            });
            if (res.ok) {
                $app.setStorageSync('userInfo', res.data);
                this.$router.replace('/user/info')
            }
        },
        validate() {
            return new Promise(resolve => {
                this.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        resolve(true);
                    } else {
                        console.log('Form 校验错误');
                    }
                });
            })
        },
    }
}
</script>

<style scoped lang="less">
.company-transfer {
    width: 13.6rem;
    text-align: left;
    
    & .button-group {
        margin-bottom: 0.2rem;
        
        & .button {
            margin-right: 0.4rem;
            color: #fff;
            padding: 0.16rem 0.4rem;
            border-radius: 0.12rem;
            cursor: pointer;
        }
    }
    
    & .card {
        background-color: #fff;
        border-radius: 0.12rem;
        padding: 0.4rem;
    }
}
.send {
    font-size: .24rem;
    width: 2.40rem;
    height: .80rem;
    background-color: #4eabe8;
    color: #fff;
    border-radius: .12rem;
    text-align: center;
    line-height: .60rem;
}
.send:active {
    opacity: 0.7;
}

.sended {
    font-size: .24rem;
    width: 2.40rem;
    height: .80rem;
    background-color: #fff;
    color: #999;
    border: .02rem solid #999;
    border-radius: .12rem;
    text-align: center;
    line-height: .56rem;
}
</style>
